import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetGoalTrackingQuery from '@/modules/flagship/investor-goal/goal-tracking/application/queries/get-goal-tracking-query';
import GetEmergencyFundInvestorGoalQuery from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import {
  GetCustomerKuspitPositionDetailsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';

// Domain
import { GoalTrackingEntity } from '@/modules/flagship/investor-goal/goal-tracking/domain/entities/goal-tracking-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class DetailsEmergencyFundModalViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.GET_GOAL_TRACKING_QUERY)
  private readonly get_goal_tracking_query!: GetGoalTrackingQuery;

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_investor_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_kuspit_position_details_query!:
    GetCustomerKuspitPositionDetailsQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.details-goals.details-emergency-fund-modal';

  is_loading = false;

  investor_goal_id = '';

  initial_amount = 0;

  strategy_name = 'PRINFGU';

  strategy_tooltip = '';

  goal_tracking: GoalTrackingEntity = {
    current_goal_balance: 0,
    expected_goal_balance: 0,
    goal_target_balance: 0,
    goal_tracking_percent: 0,
  };

  message = '';

  img = 'goals-dashboard/details-goals/be_careful.svg';

  should_have_percent = 0;

  current_percent = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (investor_goal_id: string) => {
    this.is_loading = true;
    this.investor_goal_id = investor_goal_id;
    if (this.investor_goal_id !== '') {
      await this.loadProgressGoalInformation();
      await this.loadInitialAmount();
      await this.loadInvestmentStrategies();
    }
    this.is_loading = false;
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  loadInitialAmount = async () => {
    try {
      const emergency_fund_goal = await this.get_emergency_fund_investor_goal_query.execute();
      this.initial_amount = emergency_fund_goal.investor_goal.initial_amount;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_emergency_fund_goal'));
      }
    }
  }

  loadInvestmentStrategies = async () => {
    try {
      const kuspit_position = await this.get_customer_kuspit_position_details_query.execute();
      if (kuspit_position && kuspit_position.funds) {
        const not_default_strategy = kuspit_position.funds.filter((fund) => fund.name !== 'Fondo Barrido');
        if (not_default_strategy.length) {
          this.strategy_tooltip = this.translate('tooltip_strategy_not_default');
          this.strategy_name = this.translate('other_strategy');
        } else {
          this.strategy_tooltip = this.translate('tooltip_strategy');
        }
      }
    } catch (error) {
      this.strategy_tooltip = this.translate('tooltip_strategy');
    }
  }

  loadProgressGoalInformation = async () => {
    try {
      this.goal_tracking = await this.get_goal_tracking_query.execute(this.investor_goal_id);
      // eslint-disable-next-line max-len
      this.goal_tracking.goal_tracking_percent = (this.goal_tracking.goal_tracking_percent > 100) ? 100 : Number(this.goal_tracking.goal_tracking_percent.toFixed(0));
      // eslint-disable-next-line max-len
      this.should_have_percent = (this.goal_tracking.expected_goal_balance * 100) / this.goal_tracking.goal_target_balance;
      // eslint-disable-next-line max-len
      this.current_percent = (this.goal_tracking.current_goal_balance * 100) / this.goal_tracking.goal_target_balance;
      this.setGoalProgressStatus(this.goal_tracking.goal_tracking_percent);
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_progress_goal_information'));
      }
    }
  }

  setGoalProgressStatus = (percent: number) => {
    switch (true) {
      case percent >= 80:
        this.message = this.translate('keep_it_up');
        this.img = 'goals-dashboard/details-goals/keep_it_up.svg';
        break;
      case (percent >= 65 && percent < 80):
        this.message = this.translate('youre_doing_well');
        this.img = 'goals-dashboard/details-goals/youre_doing_well.svg';
        break;
      case (percent >= 50 && percent < 65):
        this.message = this.translate('you_can_do_better');
        this.img = 'goals-dashboard/details-goals/you_can_do_better.svg';
        break;
      default:
        this.message = this.translate('be_careful');
        this.img = 'goals-dashboard/details-goals/be_careful.svg';
        break;
    }
  }
}
